<template>
    <div>
        <b-card>
            <h4>
                Token sale ends in
            </h4>
            <div class="row mt-3">
                <div class="col-4">
                    <div class="custom-badged">
                        {{ stage.is_enable == 1 ? 'Enable' : 'Disable' }}
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        {{ formatDate(stage.start) }}
                    </div>
                    <h6>
                        Start
                    </h6>
                </div>
                <div class="col-4">
                    <div>
                        {{ formatDate(stage.end) }}
                    </div>
                    <h6>
                        End
                    </h6>
                </div>
            </div>
            <div class="row mt-3">
                <span>
                    ${{stage.sold ? stage.sold : '0' }} sold out of ${{stage.maximum}}
                </span>
                <b-progress :value="calcPer(stage.sold, stage.maximum)" max="100" ></b-progress>
            </div>
            <div class="row mt-4">
                <div class="col-6">
                    <b-button variant="secondary" size="lg" class="w-100" @click="modalDelete = true">
                        Delete
                    </b-button>
                </div>
                <div class="col-6">
                    <b-button variant="primary" size="lg" class="w-100" @click="modalEdit = true">
                        Edit
                    </b-button>
                </div>
            </div>
        </b-card>
         <b-modal title="Edit token" centered hide-footer v-model="modalEdit">
            <Form :token="stage" @close="modalEdit = false" />
        </b-modal>
        <b-modal centered hide-footer title="Delete membership" v-model="modalDelete">
            <h4>
                Sure you want delete?
            </h4>
            <div class="text-right">
                <b-button variant="secondary" class="mr-3" @click="modalDelete = false">
                    Cancel
                </b-button>
                <ButtonAction :loading="loading" loadingTx="Deleting membership" title="Delete Membership" @click="confirmeDelete(stage.id)"/>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import Form from './Form.vue'
import moment from 'moment'
export default {
    props: ['stage'],
    components: {
        Form
    },
    data () {
        return {
            modalEdit: false,
            modalDelete: false,
            loading: false
        }
    },
    methods: {
        ...mapActions('stage', ['destroyStage']),
        confirmeDelete (id) {
            this.loading = true
            this.destroyStage(id).then(() => {
                this.loading = false
            })
        },
        formatDate(date){
            return moment().format('DD-MM-Y')
        },
        calcPer(sold, max) {
            let total = (sold * 100) / max
            return total
        },
    }
}
</script>
<style scoped>
    .item{
        text-align: center;
    }
    .item .bg-secondary{
        text-align: center;
    }
</style>