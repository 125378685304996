<template>
    <div>
        <div class="row">
            <div class="col-12 col-sm-6">
                <span>Start</span>
                <b-form-input type="date" v-model="form.start"/>
            </div>
            <div class="col-12 col-sm-6">
                <span>End</span>
                <b-form-input type="date" v-model="form.end"/>
            </div>
            <div class="col-12 col-sm-6 mt-3">
                <span>Maximum to sold</span>
                <b-form-input v-model="form.maximum"/>
            </div>
            <div class="col-12 col-sm-6 mt-3">
                <span>Price USD</span>
                <b-form-input v-model="form.price"/>
            </div>
            <div class="col-12 col-sm-6 pt-4">
                <b-form-group label="Is Enable">
                    <b-form-radio-group>
                        <b-form-radio v-model="form.is_enable" :aria-describedby="ariaDescribedby" name="some-radios" value="1">Enable </b-form-radio>
                        <b-form-radio v-model="form.is_enable" :aria-describedby="ariaDescribedby" name="some-radios" value="0">Disable</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="text-right mt-3">
                <b-button variant="secondary" class="mr-3">
                    Cancel
                </b-button>
                <ButtonAction :loading="loading" loadingTx="Uploading stage" title="Create stage" @click="onSubmit" v-if="!token"/>
                <ButtonAction :loading="loading" loadingTx="Uploading stage" title="Update stage" @click="onUpdate" v-else/>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
export default {
    props: ['token'],
    data () {
        return {
            form: {
                start: null,
                end: null,
                maximum: null,
                price: null,
                is_enable: 1
            },
            loading: false
        }
    },
    created () {
        if(this.token) {
            this.form = this.token
            this.form.start = this.formatDate(this.form.start)
            this.form.end = this.formatDate(this.form.end)
        }
    },
    methods: {
        ...mapActions('stage', ['storeStage', 'updateStage']),
        changeFiles(){
            this.form.image = this.$refs.image.files[0]
        },
        onSubmit () {
            this.loading = true
          
            this.storeStage(this.form).then(() => {
                this.loading = false
                openNotification()
                this.$emit('close')
            })
        },
        onUpdate () {
            this.loading = true

            this.updateStage({data: this.form, id: this.form.id}).then(() => {
                this.loading = false
                openNotification()
                this.$emit('close')
            })
        },
        formatDate (date) {
            return moment(date).format('Y-MM-DD')
        },
    }
}
</script>