<template>
    <div>
        <div class="text-right mb-3">
            <b-button size="lg" variant="primary" @click="modalToken = true">
                Create Manage
            </b-button>
        </div>
        <div v-if="loading == false">
            <div class="row" v-if="stages.length > 0">
                <div class="col-12 col-sm-6 col-md-4" v-for="stage in stages" :key="stage.id">
                    <StageCard :stage="stage" />
                </div>
            </div>
            <Emtpy />
        </div>
        <Loading type="card" v-else/>
        <b-modal centered hide-footer title="Crear Token" v-model="modalToken">
            <Form @close="modalToken = false"/>
        </b-modal>
    </div>
</template>
<script>
import StageCard from './StageCard.vue'
import Form from './Form.vue'
import { mapActions, mapState } from 'vuex'
export default {
    components: {
        StageCard,
        Form
    },
    data () {
        return {
            modalToken: false,
            loading: false
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('stage', ['getStages']),
        getData () {
            this.loading = true
            this.getStages().then(() => {
                this.loading = false
            })
        }
    },
    computed: {
        ...mapState('stage',['stages'])
    }
}
</script>